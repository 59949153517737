import React, { useState, useEffect, useRef, useMemo, forwardRef, useImperativeHandle } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { MapContainer, LayersControl, GeoJSON, ImageOverlay, FeatureGroup, Popup, useMap } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { Autocomplete, Fab, TextField, Tooltip } from "@mui/material";
import { Search } from "@mui/icons-material";
import CenterFocusWeakIcon from "@mui/icons-material/CenterFocusWeak";
import { GOOGLE_API_KEY } from "../../../../utils/googleMaps";
import { selectMapPosition, selectMapLayer } from "../../../redux/selectors";
import { setMapPosition, setMapLayer } from "../../../redux/reducer";
import { CustomMapControls, MapButton } from "./tools";


const MapRecenterTool = ({ onClick, title }) => (<Tooltip placement="right" title={title}>
    <MapButton data-cy="btn-map-recenter" onClick={onClick}>
        <CenterFocusWeakIcon />
    </MapButton>
</Tooltip>);

const updateBounds = (field, currentBounds) => {
    const bounds = currentBounds || [];
    field.boundaries.coordinates[0].forEach(c => {
        if (!bounds.length)
            bounds.push([c[1], c[0]], [c[1], c[0]]);
        else {
            if (bounds[0][0] > c[1])
                bounds[0][0] = c[1];
            if (bounds[0][1] > c[0])
                bounds[0][1] = c[0];
            if (bounds[1][0] < c[1])
                bounds[1][0] = c[1];
            if (bounds[1][1] < c[0])
                bounds[1][1] = c[0];
        }
    });
    return bounds;
};

export const FarmMap = forwardRef(({ farm, fields, fieldsHash = 0, fieldOptions, selectedFields, fieldOverlays, controls, onFieldPopup, onFieldClick, children }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const layer = useSelector(selectMapLayer);
    const mapPosition = useSelector(selectMapPosition);
    const [bounds, setBounds] = useState(null);
    const [height, setHeight] = useState(window.innerHeight);
    const [forceUpdate, setForceUpdate] = useState(0);
    const clickRef = useRef(null);
    const mapRef = useRef(null);

    useEffect(() => {
        let resizeTimer;
        const handleResize = () => {
            if (resizeTimer)
                clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                // Trigger the callback when the resize has "ended"
                setHeight(window.innerHeight);
                setTimeout(() => {
                    setBounds(b => {
                        // retrieve current bounds, not the snapshot from the closure
                        handleRecenter(b);
                        return b;   // return unmodified value
                    });
                }, 1);
            }, 500); // Adjust the debounce time as needed (in milliseconds)
        };

        // Attach the handleResize function to the window resize event
        window.addEventListener('resize', handleResize);

        // Cleanup: Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
            if (resizeTimer)
                clearTimeout(resizeTimer);
        }
    }, []);

    useEffect(() => {
        if (mapRef.current)
            mapRef.current.closePopup();
    }, [selectedFields]);

    useEffect(() => {
        if (!mapRef.current) {
            setTimeout(() => setForceUpdate(x => x + 1), 10);
            return;
        }

        if (fields?.length) computeBounds();
    }, [farm?.id, farm?.ts, fieldsHash, forceUpdate, Boolean(mapRef.current)]);

    useEffect(() => {
        if (!mapRef.current) {
            setTimeout(() => setForceUpdate(x => x + 1), 10);
            return;
        }
        if (bounds?.length) {
            handleRecenter(bounds);
        }
    }, [bounds?.[0]?.[0], bounds?.[0]?.[1], bounds?.[1]?.[0], bounds?.[1]?.[1], forceUpdate, Boolean(mapRef.current)]);

    useImperativeHandle(ref, () => ({
        computeBounds
    }));

    function computeBounds(customBounds, ignoreSelected) {
        if (customBounds) {
            setBounds(customBounds);
            return customBounds;
        }
        const farmBounds = [];
        if (fields?.length) {
            fields.forEach(field => {
                if (ignoreSelected || !selectedFields?.length || selectedFields.includes(field.id))
                    updateBounds(field, farmBounds);
            });
        }
        setBounds(farmBounds);
        return farmBounds;
    }

    function handleFieldClick(event, fieldId) {
        clickRef.current = fieldId;
        onFieldClick && onFieldClick(fieldId, event?.originalEvent?.shiftKey);
    }

    const mapProps = mapPosition || (bounds?.length
        ? { bounds }
        : {
            center: farm.location?.coordinates
                ? { lat: farm.location.coordinates[1], lng: farm.location.coordinates[0] }
                : { lat: 47.192294, lng: 8.755809 },
            zoom: 13
        }
    );

    function handleFeature(feature, layer) {
        if (feature?.properties?.id) {
            const handlers = {
                click: (e) => handleFieldClick(e, feature.properties.id)
            };
            layer.on(handlers);
        }
    };

    function handleRecenter(customBounds) {
        const map = mapRef.current;
        map && map.closePopup();
        let recenterBounds = computeBounds(customBounds);
        if (JSON.stringify(recenterBounds) === JSON.stringify(bounds))
            recenterBounds = computeBounds(customBounds, true);
        if (map && recenterBounds?.length) {
            map.fitBounds(recenterBounds, { padding: (document.body.clientWidth < 500) ? [5, 5] : [80, 80] });
        }
        dispatch(setMapPosition(null));
    }

    const MapEventHandlers = () => {
        const map = useMap();

        useEffect(() => {
            function handleClick() {
                if (clickRef.current) {
                    clickRef.current = null;
                    return;
                }
                handleFieldClick(null, null);
            }
            function handleMove() {
                const position = { center: map.getCenter(), zoom: map.getZoom() };
                dispatch(setMapPosition(position));
            }
            function handleLayer(e) {
                const layer = e.layer.options.type;
                dispatch(setMapLayer(layer));
            }
            map.on("click", handleClick);
            map.on("moveend", handleMove);
            map.on("baselayerchange", handleLayer);
            return () => {
                map.off("click", handleClick);
                map.off("moveend", handleMove);
                map.off("baselayerchange", handleLayer);
            };
        }, []);
    };

    const geojsonFields = useMemo(() => {
        if (!fields) return null;

        return fields.map((f, idx) => {
            const selected = selectedFields && selectedFields.includes(f.id)
                ? { color: "green", weight: 3, opacity: 1 }
                : {};
            const options = fieldOptions?.[f.id]
                ? { ...fieldOptions?.[f.id] }
                : { color: "blue", weight: 2, opacity: 1, fillOpacity: 0 };
            return (<GeoJSON
                key={`geo${idx} `}
                pathOptions={{ ...options, ...selected }}
                data={{ type: "Feature", geometry: f.boundaries, properties: { id: f.id } }}
                onEachFeature={handleFeature}
            >
                {(onFieldPopup || null) && <Popup>{onFieldPopup(f)}</Popup>}
            </GeoJSON>);
        });
    }, [fieldsHash, selectedFields, fieldOptions]);

    const overlays = useMemo(() => {
        if (!fieldOverlays) return null;

        return fieldOverlays.map((o, idx) => (<ImageOverlay
            key={`overlay - ${idx} `}
            url={o.url}
            bounds={o.bounds}
            opacity="100%"
            zIndex={1200}
        />))
    }, [fieldOverlays]);

    return (<MapContainer
        id="map"
        animate={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        zoomAnimation={false}
        key={`map-${height}/${farm?.ts || 0}/${fieldsHash}`}
        style={{ width: "100%", height: `${height - 50}px` }}
        ref={mapRef}
        zoomSnap={0.1}
        {...mapProps}
    >
        <LayersControl position="topright">
            <LayersControl.BaseLayer name={t("shared:farms.label-map-roadmap")} checked={!layer || layer === "roadmap"}>
                <ReactLeafletGoogleLayer apiKey={GOOGLE_API_KEY} type="roadmap" />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name={t("shared:farms.label-map-satellite")} checked={layer === "satellite"} disabled={true}>
                <ReactLeafletGoogleLayer apiKey={GOOGLE_API_KEY} type="satellite" />
            </LayersControl.BaseLayer>
        </LayersControl>
        <FeatureGroup id="fields">{geojsonFields}</FeatureGroup>
        <FeatureGroup>{overlays}</FeatureGroup>
        <FeatureGroup>
            <CustomMapControls position="topleft">
                {controls}
                <MapRecenterTool onClick={() => handleRecenter()} title={t("shared:farms.label-recenter-map")} />
            </CustomMapControls>
            <CustomMapControls position="topright">
                <FieldLocator fields={fields} onSelect={(fieldId) => handleFieldClick(null, fieldId)} />
            </CustomMapControls>
        </FeatureGroup>
        {children}
        <MapEventHandlers />
    </MapContainer>);
});

const FieldLocator = ({ fields, onSelect }) => {
    const [searchInProgress, setSearchInProgress] = useState(false);

    function handleSearchChange(event, field, reason) {
        if (reason === "blur") {
            onSelect(field.id);
        }
        setSearchInProgress(null);
    }

    return (<Fab
        component="a"
        size="small"
        sx={{ textTransform: "none" }}
        disabled={!fields?.length}
        data-cy="field-select"
    >
        {searchInProgress
            ? <Autocomplete
                ref={(input) => { input && setTimeout(() => input.click(), 100); }}
                disablePortal
                autoSelect
                selectOnFocus
                blurOnSelect
                clearOnBlur
                clearOnEscape
                openOnFocus
                autoComplete
                size="small"
                id="field-selector"
                data-cy="field-selector"
                options={fields}
                getOptionLabel={f => f.name || ""}
                renderInput={(params) => <TextField {...params} autoFocus label="Filter" />}
                sx={{ width: 300, position: "absolute", top: 0, right: 0, backgroundColor: "white" }}
                onClose={() => setSearchInProgress(null)}
                onChange={handleSearchChange}
            />
            : <Search onClick={() => setSearchInProgress(true)} />
        }
    </Fab>);
};
